<template lang="pug">
	#integration-form-root
		#integration-form-section-1
			.widget
				el-card.box-card
					el-row(slot="header")
						el-col.text-left(:span="24")
							.d-flex.justify-content-between
								h3
									b Integrations - {{ this.type }} entity

					el-form(:form="form" )
						el-row.text-left(:gutter="12")
							h5 Basic Details
							p In this part you must set the basic integration details and choose the integration type. Take a note that integration type that is not listed in the droplist (when you click on the input or type something in it).&nbsp;
								| In this case you must create an integration and add another file that describes the fields that are used in it.
							p Current status:&nbsp;
								b(v-bind:class="{'text-success': integrationExists, 'text-danger': !integrationExists}") {{ integrationExists ? 'EXISTS' : 'NOT EXISTS' }}
								el-tooltip(v-if="integrationExists" content="Click to show fields")
									font-awesome-icon.icon.alt.ml-3.chooseable(size="xl", :icon="['fas', 'circle-question']", @click="showEligibleFields")
						el-row.mt-3(:gutter="12")
							el-col(:xs="24", :sm="12" :md="6" x)
								el-form-item(label="CRM / Integration Type" label-position="top", :error="errors.type.s ? errors.type.m : ''" v-bind:class="{'is-invalid': errors.type.s}")
									el-autocomplete.w-100( class="inline-input" :fetch-suggestions="querySearch" v-model="form.type" placeholder="CRM Type" @select2="itgTypeChosed" @blur="itgTypeChosed")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Brand" :error="errors.brand_id.s ? errors.brand_id.m : ''" v-bind:class="{'is-invalid': errors.brand_id.s}")
									el-select.w-100(filterable="", v-model="form.brand_id" fi)
										el-option(value="" label="- Select Brand -")
										el-option(v-for="o of brandOptions" :value="o.v" :label="o.t")
							el-col(:xs="24", :sm="12" :md="12")
								el-form-item(label="API URL"  :error="errors.api_url.s ? errors.api_url.m : ''" v-bind:class="{'is-invalid': errors.api_url.s}")
									el-input(v-model="form.api_url" placeholder="URL to the REST API")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Short Note")
									el-input(v-model="form.note" placeholder="Short Note" :maxlength="64")
						el-divider
						el-row(:gutter="12")
							el-col(:span="24")
								//el-form-item(label="API Endpoints")
								h5.text-left
									b API Endpoints
						el-row.mt-2(:gutter="12")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Create Lead Endpoint" :error="errors.create_lead.s ? errors.create_lead.m : ''" v-bind:class="{'is-invalid': errors.create_lead.s}")
									el-input(v-model="form.create_lead" placeholder="Create Lead")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Create Customer Endpoint" :error="errors.create_customer.s ? errors.create_customer.m : ''" v-bind:class="{'is-invalid': errors.create_customer.s}")
									el-input(v-model="form.create_customer" placeholder="Create Customer ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get By Mail Endpoint" :error="errors.get_by_email.s ? errors.get_by_email.m : ''" v-bind:class="{'is-invalid': errors.get_by_email.s}")
									el-input(v-model="form.get_by_email" placeholder="Find By Email ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get By CID Endpoint" :error="errors.get_by_cid.s ? errors.get_by_cid.m : ''" v-bind:class="{'is-invalid': errors.get_by_cid.s}")
									el-input(v-model="form.get_by_cid" placeholder="Find By CID ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Deposits Endpoint" :error="errors.get_deposits.s ? errors.get_deposits.m : ''" v-bind:class="{'is-invalid': errors.get_deposits.s}")
									el-input(v-model="form.get_deposits" placeholder="Get Deposits ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Balances Endpoint" :error="errors.get_balances.s ? errors.get_balances.m : ''" v-bind:class="{'is-invalid': errors.get_balances.s}")
									el-input(v-model="form.get_balances" placeholder="Get Balance ")
						el-divider(v-if="form.type === 'api-partners'")
						el-row(v-if="form.type === 'api-partners'" :gutter="12")
							el-col.mt-2(:span="24")
								//el-form-item(label="API Endpoints - API Partners only")
								h5.text-left
									b API Endpoints - API Partners only
						el-row.mt-2(v-if="form.type === 'api-partners'" :gutter="12")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Close CFD Action Endpoint" )
									el-input(v-model="form.close_cfd_action" placeholder="Close CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Edit CFD Action Endpoint" )
									el-input(v-model="form.edit_cfd_action" placeholder="Edit CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Asset Rate Endpoint" )
									el-input(v-model="form.get_asset_rate" placeholder="Get Asset Rate")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Assets (Symbols) Endpoint" )
									el-input(v-model="form.get_assets" placeholder="Get Assets (Symbols)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get CFD Options Endpoint" )
									el-input(v-model="form.get_cfd_options" placeholder="Get CFD Options")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Options (Rates) Endpoint" )
									el-input(v-model="form.get_options" placeholder="Get Options (Rates)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Orders Endpoint" )
									el-input(v-model="form.get_orders" placeholder="Get Orders")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Trade Actions Endpoint" )
									el-input(v-model="form.get_trade_actions" placeholder="Get Trade Actions (Trades)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Place CFD Action Endpoint" )
									el-input(v-model="form.place_cfd_action" placeholder="Place CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Place Order Endpoint" )
									el-input(v-model="form.place_order" placeholder="Place Order")

						el-divider
						el-row(:gutter="12")
							el-col(:span="24")
								h5.text-left
									b Custom Links
						el-row(:gutter="12")
							el-col(:xs="24" )
								el-form-item()
									template(slot="label")
										label Custom API endpoints - keys must differ from the above, otherwise can override them.&nbsp;
											el-tooltip(:content="keysToIgonre.join(', ')")
												span.chooseable Hover the question icon to view the keys.
									.d-flex.justify-content-between5
										el-tooltip(:content="keysToIgonre.join(', ')")
											i.el-icon-question.chooseable.mr-3
										el-tooltip(content="Add a new line")
											el-button(type="info" icon="el-icon-plus" @click="addRow('links')") Add
							el-col(:xs="24" :md="18" :lg="12")
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.links.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.links" :gutter="6")
									el-col(:span="4")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="19")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")

										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="shrinkLinksArray(i)")

						el-divider
						el-row(:gutter="12")
							el-col(:span="24")
								h5.text-left
									b Integration Params, Credentials and Misc
						el-row.text-left.mt-3()
							el-col(:xs="24" :sm="12" :md="8")
								el-form-item()
									template(slot="label")
										span Fields to avoid&nbsp;
											small.text-success (These fields will not be sent to the brands)
									el-select.w-100(multiple="" filterable="" allow-create="" placeholder="Fields that you want to avoid sending" v-model="form.ignore_fields_list")
										el-option(label="Select from the list or add new" value="null" disabled="")
										el-option(v-for="o of typeAvoidOptions" :label="o" :value="o")
						el-row.text-left.mt-3(:gutter="12")
							el-col(:span="24")
								el-form-item(label="MISC Params")
									el-tooltip(content="Add a new line")
										el-button(type="info" icon="el-icon-plus" @click="addRow('misc')") Add
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.misc.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.misc" :gutter="6")
									el-col(:span="8")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="15")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")
										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="() => form.misc.splice(i,1)")
						el-divider

						el-row.text-left(:gutter="12")
							el-col(:span="24")
								el-form-item(label="Creds Params")
									el-tooltip(content="Add a new line")
										el-button(type="info" icon="el-icon-plus" @click="addRow('creds')") Add
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.creds.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.creds" :gutter="6")
									el-col(:span="8")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="15")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")
										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="() => form.creds.splice(i,1)")
						el-row.mt-5(:gutter="12" justify="between")
							el-button(@click="submit" type="primary") Save
							el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel
		#integration-form-section-2
			el-dialog.override-width.search-dialog(title="Integration Eligible Fields"  :visible.sync='showEligibleFieldsModal', width='75%', center='' @closed="() => this.showEligibleFieldsModal = false")
				el-row(:gutter="24")
					el-col(:span="24")
						label Body
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.body.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.body")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
								b(v-if="tag.father") Related to field:&nbsp;
								span(v-if="tag.father") {{tag.father}}
								br(v-if="tag.father")
								b(v-if="tag.index") Index in array:&nbsp;
								span(v-if="tag.index") {{tag.index}}
								br(v-if="tag.index")

							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" )
									el-tag() {{ tag.key }}
							.mr-3.d-inline
								el-tag() {{ tag.key }}
				el-row.mt-3(:gutter="24")
					el-col(:span="24")
						label Misc
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.misc.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.misc")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" )
									el-tag(type="danger" ) {{ tag.key }}
							.mr-3.d-inline
								el-tag(type="danger" ) {{ tag.key }}
				el-row.mt-3(:gutter="24")
					el-col(:span="24")
						label Creds
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.creds.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.creds")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" v-if="tag.mandatory")
									el-tag(type="success" ) {{ tag.key }}
							.mr-3.d-inline
								el-tag(type="success" ) {{ tag.key }}



</template>


<style lang="scss">

</style>


<script lang="js">
import Vue from 'vue';
import validator from 'validator';
import { debounce } from 'vue-debounce'

let keysToIgonre = [
		'host',
	'closeCfdAction',
	'editCfdAction',
	'getAssetRate',
	'getAssets',
	'getCfdOptions',
	'getOptions',
	'getOrders',
	'getTradeActionsReport',
	'placeCfdAction',
	'placeOrder',
	'createLead',
	'createCustomer',
	'getByEmail',
	'getByMail',
	'getByCid',
	'getDeposits',
	'getBalances',
]

const $currentIntegrationFields = {
    hash: { body: {}, misc: {}, creds: {} },
    list: { body: [], misc: [], creds: [] }
}

export default {
	name: 'integration-form',
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-integration' && !to.query.id) {
			try {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,49f06cec-46b0-4237-bf9a-d4d3969e5af6'})
				next(vm => {
					vm.typeOptions = options.crm_types;
					vm.brandOptions = options.brand;
				})
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		} else if (to.name === 'iframe-edit-integration' || (to.name === 'iframe-add-integration' && to.query.id)) {
			try {
				let type = (to.name === 'iframe-add-integration' && to.query.id) ? 'query' : 'params'
				let result = await Vue.apix.sendHttpRequest('GET', 'integration/get', {id: to[type].id});
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,49f06cec-46b0-4237-bf9a-d4d3969e5af6'})
				let itg = await Vue.apix.sendHttpRequest('GET','integration/integration-fields/' + (result.type || ''))



        next(vm => {
					vm.typeOptions = options.crm_types;
					vm.brandOptions = options.brand;
					vm.counter = {
						misc: 1,
						creds: 1,
						links: 0
					},
              //======//
              vm.integrationExists = itg.exists;
            vm.currentIntegrationFields = JSON.parse(JSON.stringify($currentIntegrationFields));
						if(itg.content)
									Object.values(itg.content).forEach(e => {
                    vm.currentIntegrationFields.hash[e.params][e.key] = e;
                    vm.currentIntegrationFields.list[e.params].push(e);
                    vm.typeAvoidOptions.push(e.key);
									})

            vm.typeAvoidOptions = [...vm.typeAvoidOptions.sort()]
            //======//
							vm.integrationExists = itg.exists;
							vm.entity_id = to[type].id,
							vm.type = (to.name === 'iframe-add-integration' && to.query.id) ? 'clone' : 'edit',
							vm.form = {
								ignore_fields_list: result.ignore_fields_list,
								brand_id: result.brand_id || '',
								type: result.type || '',
								api_url: result.api_url || '',
								creds: [],
								misc: [],
								links: [],
								note: result.note,
								create_lead: result.misc.api_url.create_lead || result.misc.api_url.createLead || '',
								create_customer: result.misc.api_url.create_customer || result.misc.api_url.createCustomer || '',
								get_by_email: result.misc.api_url.get_by_email || result.misc.api_url.getByMail || '',
								get_by_cid: result.misc.api_url.get_by_id || result.misc.api_url.getByCid || '',

								// Extra
								get_deposits: result.misc.api_url.get_deposits || result.misc.api_url.getDeposits || '',
								get_balances: result.misc.api_url.get_balances || result.misc.api_url.getBalances || '',
							};

					Object.entries(result.misc.api_url).forEach(e => {
						if(!keysToIgonre.includes(e[0])) {
							vm.counter.links++;
							vm.form.links.push({key: e[0], value: e[1]})
						}
					})


					// Api Partners - Only relevant to API Partners
					if (result.misc.api_url.closeCfdAction) vm.form.close_cfd_action = result.misc.api_url.closeCfdAction;
					if (result.misc.api_url.editCfdAction) vm.form.edit_cfd_action = result.misc.api_url.editCfdAction;
					if (result.misc.api_url.getAssetRate) vm.form.get_asset_rate = result.misc.api_url.getAssetRate;
					if (result.misc.api_url.getAssets) vm.form.get_assets = result.misc.api_url.getAssets;
					if (result.misc.api_url.getCfdOptions) vm.form.get_cfd_options = result.misc.api_url.getCfdOptions;
					if (result.misc.api_url.getOptions) vm.form.get_options = result.misc.api_url.getOptions;
					if (result.misc.api_url.getOrders) vm.form.get_orders = result.misc.api_url.getOrders;
					if (result.misc.api_url.getTradeActionsReport) vm.form.get_trade_actions = result.misc.api_url.getTradeActionsReport;
					if (result.misc.api_url.placeCfdAction) vm.form.place_cfd_action = result.misc.api_url.placeCfdAction;
					if (result.misc.api_url.placeOrder) vm.form.place_order = result.misc.api_url.placeOrder;

					vm.form.creds = Object.entries(result.creds).map(e => Object.assign({}, {key: e[0], value: e[1]}));
					let misc = {...result.misc};
					delete misc.api_url;
					vm.form.misc = Object.entries(misc).map(e => Object.assign({}, {key: e[0], value: e[1]}));
				})
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		}
	},

	data() {
		return {
			type: 'create',
			keysToIgonre,
			counter: {
				misc: 1,
				creds: 1,
				links: 1
			},
        showEligibleFieldsModal: false,
        integrationExists: false,
				currentIntegrationFields: JSON.parse(JSON.stringify($currentIntegrationFields)),
			form: {
				brand_id: '',
				type: '',
				api_url: '',
				links: [],
				creds: [],
				misc: [],
				note: '',
				create_lead: '',
				create_customer: '',
				get_by_email: '',
				get_by_cid: '',
				ignore_fields_list: '',

				// Extra
				get_deposits: '',
				get_balances: ''


			},
			errors: {
				name: {s: false, m: ''},
				brand_id: {s: false, m: ''},
				type: {s: false, m: ''},
				api_url: {s: false, m: ''},
				create_lead: {s: false, m: ''},
				create_customer: {s: false, m: ''},
				get_by_email: {s: false, m: ''},
				get_by_cid: {s: false, m: ''},

				// Extra
				get_deposits: {s: false, m: ''},
				get_balances: {s: false, m: ''}
			},
			typeAvoidOptions: [],
			typeOptions: [],
			brandOptions: [],
			tree: {
				misc: {},
				creds: {}
			}
		}
	},

	methods: {
      showEligibleFields() {
				this.showEligibleFieldsModal = true;

			},
      itgTypeChosed($event) {
					setTimeout(() => {
              this.typeAvoidOptions = [];
              this.form.ignore_fields_list = [];
              this.$apix.sendHttpRequest('GET','integration/integration-fields/' + $event.target.value)
                  .then(res => {

											this.integrationExists = res.exists;
                      this.currentIntegrationFields = JSON.parse(JSON.stringify($currentIntegrationFields)),
											Object.values(res.content).forEach(e => {
                          this.currentIntegrationFields.hash[e.params][e.key] = e;
                          this.currentIntegrationFields.list[e.params].push(e);
                          this.typeAvoidOptions.push(e.key);
											})

                      // this.typeAvoidOptions = [...typeAvoidOptions.sort().map(e => Object.assign({}, {link: e, value:e}))]
                      this.typeAvoidOptions = [...this.typeAvoidOptions.sort()]

                  }).catch(e => {
                      console.error(e);

							})
					}, 750);
      },
		shrinkLinksArray (i) {
			this.form.links.splice(i,1);
		},

		querySearch(qs, cb) {
			let typeOptions = this.typeOptions;
			let results = qs ? typeOptions.filter(this.createFilter(qs)) : typeOptions
			cb(results)
		},
		createFilter(queryString) {
			return (link) => {
				return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		addRow(type) {
			this.form[type].push({key: `key_${this.counter[type]}`, value: `value_${this.counter[type]}`});
			this.counter[type]++;
		},
		submit() {
			if (!this.validate()) return console.log(this.errors);
			this.buildJSON('creds');
			this.buildJSON('misc');
			if (this.type === 'create' || this.type === 'clone') {
				this.$apix.sendHttpRequest('POST', 'integration/create', {
					data: {
						type: this.form.type,
						api_url: this.form.api_url,
						creds: this.tree.creds,
						misc: this.tree.misc,
						note: this.form.note,
						brand_id: this.form.brand_id,
            ignore_fields_list: this.form.ignore_fields_list
					}
				}).then(res => {
					if (!!res.id) this.$router.push({name: 'iframe-list'})
				}).catch(err => console.error(err))
			} else {
				this.$apix.sendHttpRequest('PUT', 'integration/update/' + this.entity_id, {
					data: {
						type: this.form.type,
						api_url: this.form.api_url,
						creds: this.tree.creds,
						misc: this.tree.misc,
						note: this.form.note,
						brand_id: this.form.brand_id,
						ignore_fields_list: this.form.ignore_fields_list
					}
				}).then(res => {
					this.$router.push({name: 'iframe-list'})
				}).catch(err => console.error(err))
			}
		},
		validate() {
			this.errors = {
				type: {s: false, m: ''},
				brand_id: {s: false, m: ''},
				api_url: {s: false, m: ''},
				create_lead: {s: false, m: ''},
				create_customer: {s: false, m: ''},
				get_by_email: {s: false, m: ''},
				get_by_cid: {s: false, m: ''},
				get_deposits: {s: false, m: ''},
				get_balances: {s: false, m: ''},
			}
			let error = false;

			if (this.form.type === '') {
				this.errors.name = {s: true, m: 'Type cannot be empty'};
				error = true;
			} else if (!validator.isAscii(this.form.type)) {
				this.errors.name = {s: true, m: 'Type must be alphanumeric'};
				error = true;
			}

			if (this.form.brand_id === '') {
				this.errors.brand_id = {s: true, m: 'Brand is mandatory'};
				error = true;
			}

			// if(this.form.api_url === '') {
			//     this.errors.api_url = {s:true, m: 'API URL cannot be empty'};
			//     error = true;
			// } else if(!validator.isURL(this.form.api_url)) {
			//     this.errors.api_url = {s:true, m: 'API URL malformed'};
			//     error = true;
			// }
			//
			// if(this.form.create_lead === '') {
			//     this.errors.create_lead = {s:true, m: 'Type cannot be empty'};
			//     error = true;
			// } else if(!validator.isAscii(this.form.create_lead)) {
			//     this.errors.create_lead = {s:true, m: 'Type must be alphanumeric'};
			//     error = true;
			// }
			//
			// if(this.form.create_customer === '') {
			//     this.errors.create_customer = {s:true, m: 'Type cannot be empty'};
			//     error = true;
			// } else if(!validator.isAscii(this.form.create_customer)) {
			//     this.errors.create_customer = {s:true, m: 'Type must be alphanumeric'};
			//     error = true;
			// }

			// if(this.form.get_by_email === '') {
			//     this.errors.get_by_email = {s:true, m: 'Type cannot be empty'};
			//     error = true;
			// } else if(!validator.isAscii(this.form.get_by_email)) {
			//     this.errors.get_by_email = {s:true, m: 'Type must be alphanumeric'};
			//     error = true;
			// }
			//
			// if(this.form.get_by_cid === '') {
			//     this.errors.get_by_cid = {s:true, m: 'Type cannot be empty'};
			//     error = true;
			// } else if(!validator.isAscii(this.form.get_by_cid)) {
			//     this.errors.get_by_cid = {s:true, m: 'Type must be alphanumeric'};
			//     error = true;
			// }

			return !error;
		},
		buildJSON(type) {
			if (type === 'creds') {
				this.form.creds.forEach(e => {
					this.tree.creds[e.key] = e.value;
				});
			} else {
				this.form.misc.forEach(e => {
					this.tree.misc[e.key] = e.value;
				});
				this.tree.misc['api_url'] = {
					host: this.form.api_url,
					createCustomer: this.form.create_customer,
					createLead: this.form.create_lead,
					getByMail: this.form.get_by_email,
					getByCid: this.form.get_by_cid,
					getDeposits: this.form.get_deposits,
					getBalances: this.form.get_balances,
				}
				this.form.links.forEach(l => {
					this.tree.misc.api_url[l.key] = l.value
				});

				if (this.form.close_cfd_action) this.tree.misc['api_url']['closeCfdAction'] = this.form.close_cfd_action;
				if (this.form.edit_cfd_action) this.tree.misc['api_url']['editCfdAction'] = this.form.edit_cfd_action;
				if (this.form.get_asset_rate) this.tree.misc['api_url']['getAssetRate'] = this.form.get_asset_rate;
				if (this.form.get_assets) this.tree.misc['api_url']['getAssets'] = this.form.get_assets;
				if (this.form.get_cfd_options) this.tree.misc['api_url']['getCfdOptions'] = this.form.get_cfd_options;
				if (this.form.get_options) this.tree.misc['api_url']['getOptions'] = this.form.get_options;
				if (this.form.get_orders) this.tree.misc['api_url']['getOrders'] = this.form.get_orders;
				if (this.form.get_trade_actions) this.tree.misc['api_url']['getTradeActionsReport'] = this.form.get_trade_actions;
				if (this.form.place_cfd_action) this.tree.misc['api_url']['placeCfdAction'] = this.form.place_cfd_action;
				if (this.form.place_order) this.tree.misc['api_url']['placeOrder'] = this.form.place_order;
			}
		}
	},

}
</script>
